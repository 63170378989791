.loginWrapper {
    position: absolute;
	top: 40%;
	left: 50%;
	margin: -160px 0 0 -160px;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 320px;
	height: 320px;
	padding: 36px;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
}