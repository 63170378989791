.tasksContainer {
    .actions {
        margin-bottom: 24px;
        display: flex;
        .search {
            margin-left: auto;
        }
    }

    .expandedWrapper {
        display: flex;
        align-items: center;
        height: auto;
    }
}